interface pageActionsModel {
  PageName: string,
  ActionNames?: { [key: string]: string }
}
export const pageActions: pageActionsModel[] = [
  {
    PageName: 'User Management',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Role Management',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      Delete: 'Delete'
    }
  },
  {
    PageName: 'Examiner Profile',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Training Records',
    ActionNames: {
      View: 'View',
      Detail: 'Detail',
      Log: 'Log'
    }
  },
  {
    PageName: 'Monitor Records',
    ActionNames: {
      View: 'View',
      Detail: 'Detail',
      Log: 'Log'
    }
  },
  {
    PageName: 'Certification Records',
    ActionNames: {
      View: 'View',
      Edit: 'Edit',
      ViewLog: 'View Log'
    }
  },
  {
    PageName: 'Lock Availability',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Delete: 'Delete',
    }
  }, {
    PageName: 'Non-Examining Date',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Modify: 'Modify',
      Delete: 'Delete',
    }
  }, {
    PageName: 'Core Day Management',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Modify: 'Modify',
      ReleaseToIEP: 'Release To IEP',
    }
  }, {
    PageName: 'Availability Management',
    ActionNames: {
      View: 'View',
      Modify: 'Modify',
      ExportAvailabilitySummary: 'ExportAvailabilitySummary',
      ViewLog: 'ViewLog',
    }
  },
  , {
    PageName: 'Default settings',
    ActionNames: {
      View: 'View',
      Edit: 'Edit',
    }
  },{
    PageName: 'Central Allocation',
    ActionNames: {
      View: 'View',
      Modify: 'Modify',
      Details: 'View Details',
      Allocate: 'Allocate',
      Export: 'Export',
      ViewLog: 'View Log',
    }
  },
  {
    PageName: 'Upload OSM Work Records',
    ActionNames: {
      Download: 'Download',
      Upload: 'Upload',
      View: 'View',
      Delete: 'Delete',
    }
  },
  {
    PageName: 'Global Examiner Profile',
    ActionNames: {
      View: 'View',
      Add: 'Add',
      Edit: 'Edit',
      ViewLog: 'View log',
    }
  },
]
