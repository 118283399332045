import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '@/views/layout/index.vue'

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      isMain: true
    },
    children: []
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/login/success',
    name: 'loginSuccess',
    component: () => import('../views/login/success.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    meta: { hidden: true }
  }
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/user',
    name: 'user management',
    meta: {
      title: 'User management',
      keepAlive: true,
    },
    redirect: '/user/list',
    component: Main,
    children: [
      {
        path: 'role/list',
        name: 'roleList',
        component: () => import('../views/user/role/index.vue'),
        meta: {
          permissionPageName: 'Role Management',
          title: 'Role List',
          keepAlive: true,
        }
      },
      {
        path: 'list',
        name: 'userList',
        component: () => import('../views/user/index.vue'),
        meta: {
          permissionPageName: 'User Management',
          title: 'User List',
          keepAlive: true,
        }
      },
    ]
  },
  {
    path: '/examinerManagement',
    name: 'examiner info management',
    redirect: '/examinerManagement/globalExaminerProfile',
    meta: {
      title: 'Examiner'
    },
    component: Main,
    children: [
      {
        path: 'globalExaminerProfile',
        name: 'globalExaminerProfile',
        component: () => import('@/views/examinerManagement/globalExaminerProfile/index.vue'),
        meta: {
          permissionPageName: 'Global Examiner Profile',
          title: 'Global Examiner Profile',
          keepAlive: true
        }
      },
      {
        path: 'examinerProfile',
        name: 'examinerProfile',
        component: () => import('@/views/examinerManagement/examinerProfile/index.vue'),
        meta: {
          permissionPageName: 'Examiner Profile',
          title: 'Examiner Profile',
          keepAlive: true
        }
      },
      {
        path: 'examiner-detail/:examinerId',
        name: 'examinerDetail',
        component: () => import('@/views/examinerManagement/examinerProfile/examinerDetail/index.vue'),
        meta: {
          permissionPageName: 'Examiner Profile',
          title: 'Examiner Profile / Examiner Personal Info',
          hidden: true,
          isHiddenTitle: true
        }
      },
      {
        path: 'examiner-records',
        name: 'examinerRecords',
        component: () => import('@/views/examinerManagement/examinerRecords/index.vue'),
        meta: {
          permissionPageName: 'Certification Records',
          title: 'Certification Records',
          keepAlive: true
        }
      },
      {
        path: 'examiner-records/:id',
        name: 'examinerRecordsDetail',
        component: () => import('@/views/examinerManagement/examinerRecords/detail/index.vue'),
        meta: {
          permissionPageName: 'Certification Records',
          title: 'Certification Records Detail',
          hidden: true,
          isHiddenTitle: true
        }
      },
      {
        path: 'training-Records',
        name: 'Training Records',
        component: () => import('@/views/examinerManagement/trainingRecords/index.vue'),
        meta: {
          permissionPageName: 'Training Records',
          title: 'Training Records',
          keepAlive: true
        }
      },
      {
        path: 'monitor-Records',
        name: 'Monitor Records',
        component: () => import('@/views/examinerManagement/monitorRecords/index.vue'),
        meta: {
          permissionPageName: 'Monitor Records',
          title: 'Monitor Records',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/coreDayManagement',
    name: 'Core Day Management',
    redirect: '/coreDayManagement/index',
    meta: {
      title: 'Core Day Management'
    },
    component: Main,
    children: [
      {
        path: 'index',
        name: 'Core Day Management',
        component: () => import('@/views/coreDayManagement/index.vue'),
        meta: {
          permissionPageName: 'Core Day Management',
          title: 'Core Day Management',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/availabilityManagement',
    name: 'Availability Management',
    redirect: '/availabilityManagement/index',
    meta: {
      title: 'Availability Management'
    },
    component: Main,
    children: [
      {
        path: 'index',
        name: 'Availability Management',
        component: () => import('@/views/availabilityManagement/availibility/index.vue'),
        meta: {
          permissionPageName: 'Availability Management',
          title: 'Availability Management',
          keepAlive: true
        }
      },
      {
        path: 'lock-availability',
        name: 'lock-availability',
        component: () => import('@/views/availabilityManagement/lockAvailability/index.vue'),
        meta: {
          permissionPageName: 'Lock Availability',
          title: 'Lock Availability'
        }
      },
      {
        path: 'modifyAvailability/:date',
        name: 'modifyAvailability',
        component: () => import('@/views/availabilityManagement/availibility/modifyAvailability/index.vue'),
        meta: {
          permissionPageName: 'Availability Management',
          title: 'Modify Availability',
          hidden: true,
          isHiddenTitle: true
        }
      }, {
        path: 'NonExaminingDate',
        name: 'Non-Examining Date',
        component: () => import('@/views/availabilityManagement/noneExaminingDate/index.vue'),
        meta: {
          permissionPageName: 'Non-Examining Date',
          title: 'Non-Examining Date',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/cenralAllocation',
    name: 'Central allocation',
    redirect: '/cenralAllocation/CentralAllocation',
    meta: {
      title: 'Central Allocation'
    },
    component: Main,
    children: [
      {
        path: 'CentralAllocation',
        name: 'Central Allocation',
        component: () => import('@/views/cenralAllocation/centralAllocation/index.vue'),
        meta: {
          permissionPageName: 'Central Allocation',
          title: 'Central Allocation',
          keepAlive: true
        },
      },
      {
        path: '/cenralAllocation/CentralAllocation/AllocationDetails/:planId/:workDate',
        name: 'AllocationDetails',
        component: () => import('@/views/cenralAllocation/allocationDetails/index.vue'),
        meta: {
          permissionPageName: 'Central Allocation',
          title: 'Central Allocation / Allocation Details',
          hidden: true,
          isHiddenTitle: true
        }
      },
      {
        path: 'index',
        name: 'Default Settings',
        component: () => import('@/views/cenralAllocation/defaultSettings/index.vue'),
        meta: {
          permissionPageName: 'Default settings',
          title: 'Default Settings',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/uploadOSMWorkRecords',
    name: 'Upload OSM Work Records',
    redirect: '/uploadOSMWorkRecords/index',
    meta: {
      title: 'Upload OSM Work Records'
    },
    component: Main,
    children: [
      {
        path: 'index',
        name: 'Upload OSM Work Records',
        component: () => import('@/views/uploadOSMWorkRecord/index.vue'),
        meta: {
          permissionPageName: 'Upload OSM Work Records',
          title: 'Upload OSM Work Records',
          keepAlive: true
        }
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
})

export default router
